/**
 * 給与を受け取り、2桁までの数字に変換する
 * @param salary
 * @returns
 */
export const salaryConvertor = (salary: number | null) => {
  if (!salary) return null
  // 2桁~3桁の場合はそのまま返却する
  if (salary < 1000) {
    return salary
  }
  // １万以上は切り捨てして返却するように変換する
  return Math.floor(salary / 10000)
}

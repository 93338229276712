import { Badge, Flex } from '@chakra-ui/react'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
}

const Tag: React.FC<Props> = ({ children, ...style }) => {
  return (
    <Badge
      p="6px 8px"
      w="fit-content"
      borderRadius="4px"
      fontSize="12px"
      lineHeight="16px"
      fontWeight={400}
      bg="surface.secondary"
      color="text.secondary"
      {...style}
    >
      <Flex alignItems="center" gap={1}>
        {children}
      </Flex>
    </Badge>
  )
}

export default Tag

export const GoodIcon = ({ size }: { size?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? '21'}
      height={size ?? '20'}
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M2.5 10.5C2.5 9.67157 3.17157 9 4 9C4.82843 9 5.5 9.67157 5.5 10.5V16.5C5.5 17.3284 4.82843 18 4 18C3.17157 18 2.5 17.3284 2.5 16.5V10.5Z"
        fill="white"
      />
      <path
        d="M6.5 10.3333V15.7639C6.5 16.5215 6.928 17.214 7.60557 17.5528L7.65542 17.5777C8.21084 17.8554 8.82329 18 9.44427 18H14.8604C15.8138 18 16.6346 17.3271 16.8216 16.3922L18.0216 10.3922C18.2691 9.15465 17.3225 8 16.0604 8H12.5V4C12.5 2.89543 11.6046 2 10.5 2C9.94772 2 9.5 2.44772 9.5 3V3.66667C9.5 4.53215 9.21929 5.37428 8.7 6.06667L7.3 7.93333C6.78071 8.62572 6.5 9.46785 6.5 10.3333Z"
        fill="white"
      />
    </svg>
  )
}

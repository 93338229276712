import FaceIconA from '@/modules/assets/images/zetomaru/zetomaru_faceicon_a.svg'
import FaceIconB from '@/modules/assets/images/zetomaru/zetomaru_faceicon_b.svg'
import FaceIconC from '@/modules/assets/images/zetomaru/zetomaru_faceicon_c.svg'
import Congratulations from '@/modules/assets/images/zetomaru/zetomaru_fig_congratulations.svg'
import FullA from '@/modules/assets/images/zetomaru/zetomaru_full_48_a.svg'
import FullB from '@/modules/assets/images/zetomaru/zetomaru_full_48_b.svg'
import FullC from '@/modules/assets/images/zetomaru/zetomaru_full_48_c.svg'
import FullD from '@/modules/assets/images/zetomaru/zetomaru_full_48_d.svg'
import FullE from '@/modules/assets/images/zetomaru/zetomaru_full_48_e.svg'
import Half from '@/modules/assets/images/zetomaru/zetomaru_half_48.svg'
import ProfilePic from '@/modules/assets/images/zetomaru/zetomaru_profilepic.svg'

export type CharacterVariant =
  | 'profilepic'
  | 'faceiconA'
  | 'faceiconB'
  | 'faceiconC'
  | 'half'
  | 'fullA'
  | 'fullB'
  | 'fullC'
  | 'fullD'
  | 'fullE'
  | 'congratulations'

export const getCharacterIconSource = (variant?: CharacterVariant): string => {
  switch (variant) {
    case 'profilepic':
      return ProfilePic.src
    case 'faceiconA':
      return FaceIconA.src
    case 'faceiconB':
      return FaceIconB.src
    case 'faceiconC':
      return FaceIconC.src
    case 'half':
      return Half.src
    case 'fullA':
      return FullA.src
    case 'fullB':
      return FullB.src
    case 'fullC':
      return FullC.src
    case 'fullD':
      return FullD.src
    case 'fullE':
      return FullE.src
    case 'congratulations':
      return Congratulations.src
    default:
      return ProfilePic.src
  }
}

export const CheckIcon = ({
  color,
  width,
  height,
}: {
  color?: string
  width?: number
  height?: number
}) => {
  return (
    <svg
      width={width ?? 16}
      height={height ?? 16}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7262 6.35147C20.1761 6.8201 20.1761 7.5799 19.7262 8.04853L10.5102 17.6485C10.0603 18.1172 9.33091 18.1172 8.88102 17.6485L4.27302 12.8485C3.82314 12.3799 3.82314 11.6201 4.27302 11.1515C4.72291 10.6828 5.45231 10.6828 5.9022 11.1515L9.6956 15.1029L18.097 6.35147C18.5469 5.88284 19.2763 5.88284 19.7262 6.35147Z"
        fill={color ?? '#4BD281'}
      />
    </svg>
  )
}

export default CheckIcon

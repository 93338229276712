import { format as dateFnsFormat } from 'date-fns'
import dayjs from 'dayjs'
import isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin

import 'dayjs/locale/ja' // import locale

dayjs.extend(isLeapYear) // use plugin
dayjs.locale('ja') // use locale

/**
 * dateを指定したフォーマットに変換する
 */
export const formatDate = (date: string, format: string): string => {
  return dateFnsFormat(new Date(date), format)
}

/**
 * ab_modified_at専用の変換ロジック
 */
export const formatDateForABModifiedAt = (date: string, format: string): string => {
  const [splitDate] = date.split(' ')
  const newDate = new Date(splitDate)
  return dateFnsFormat(newDate, format)
}

/**
 * TODO: date-fns化
 *
 * 日付を引数に渡すと、現在時刻からの相対表示にして返す。
 * 表記ルールは下記参照
 * https://scouter.esa.io/posts/10438#%E7%9B%B8%E5%AF%BE%E6%99%82%E9%96%93%E3%82%92%E4%BD%BF%E3%81%A3%E3%81%9F%E8%A1%A8%E7%8F%BE
 * targetに差分をとる対象の時刻を付け加えらる。無ければ現在時刻を使用。
 *
 *
 * @export
 * @param {string} date
 * @param {string|undefined} target
 * @returns {string}
 */
export function formatToRelativeTimeString(
  date: string,
  target: string | undefined = undefined,
): string {
  const diffDays = dayjs(target).diff(dayjs(date), 'days')
  const diffHours = dayjs(target).diff(dayjs(date), 'hours')
  const diffMinutes = dayjs(target).diff(dayjs(date), 'minutes')
  const diffSeconds = dayjs(target).diff(dayjs(date), 'second')

  if (diffSeconds < 60) return '今'
  if (diffMinutes < 60) return `${diffMinutes}分前`
  if (diffHours < 5) return `${diffHours}時間前`
  if (dayjs(target).isSame(dayjs(date), 'day')) return `今日 ${dayjs(date).format('HH:mm')}`
  if (diffDays < 2) return `昨日 ${dayjs(date).format('HH:mm')}`
  if (diffDays < 8) return dayjs(date).format('ddd HH:mm')
  if (dayjs(target).isSame(dayjs(date), 'year')) return dayjs(date).format('M/DD')
  return dayjs(date).format('YYYY/M/DD')
}

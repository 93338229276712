import DefaultUserIcon from '@/assets/img/scout/DefaultUserIcon.svg'
import M1 from '@/assets/img/scout/Matching_1.svg'
import M2 from '@/assets/img/scout/Matching_2.svg'
import M3 from '@/assets/img/scout/Matching_3.svg'
import MainBadge from '@/modules/components/MainBadge'
import { getCharacterIconSource } from '@/modules/utils/character'
import { ScoutInquiryType, ScoutSenderType } from '@/types/Scout'
import { decodeBase32 } from '@/utils/decodeBase32'

export const getInquiryTagText = (scoutType: ScoutInquiryType) => {
  switch (scoutType) {
    case 'normalScout':
      return 'スカウト'
    case 'skipDocumentScreeningScout':
      return '面接確約スカウト🎉'
    case 'systemRecommendedJob':
      return ''
    default:
      return ''
  }
}

/**
 * タグ部分の出し分け
 */
export const ScoutInquiryTypeTag = ({ scoutType }: { scoutType: ScoutInquiryType }) => {
  const tagText = getInquiryTagText(scoutType)
  return tagText ? (
    <MainBadge bg="surface.reverse-primary" text={getInquiryTagText(scoutType)} />
  ) : (
    ''
  )
}

/**
 * CAプロフィール画像URLを scoutId から生成
 */
const getCAProfileImageUrl = (scoutId: string): string => {
  // ULIDを数値に変換して30で割った余りをIDとして使う
  // why?: 後ろから5文字を取得しているのは、スカウトIDの末尾の文字で変化があるため
  const scoutIdNumber = decodeBase32(scoutId.toUpperCase().slice(-5))
  const id = (scoutIdNumber % 30) + 1
  return `/profile_pic/profile_pic_${id.toString().padStart(2, '0')}.svg`
}

/**
 * プロフィール画像の出し分け
 */
export const getScoutSenderIcon = (
  scoutType: ScoutSenderType,
  scoutId: string,
  senderImageUrl?: string,
) => {
  switch (scoutType) {
    case 'systemRecommendedJob':
      return getCharacterIconSource('profilepic')
    case 'companyScout':
      return DefaultUserIcon.src ?? senderImageUrl
    case 'careerAdviserScout':
      return getCAProfileImageUrl(scoutId) ?? DefaultUserIcon.src
    default:
      return DefaultUserIcon.src
  }
}
/**
 * 実現したいことスコアの記号出し分け
 */
export const getEvaluationAmbitionIcon = (score: number) => {
  // 4.0~5.0: ◎
  // 3.0~3.9: ◯
  // 0.0~2.9: △
  if (score >= 4.0) {
    return M1.src
  } else if (score >= 3.0) {
    return M2.src
  } else {
    return M3.src
  }
}

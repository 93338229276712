import { ScoutInquiryType, ScoutSenderType } from '@/types/Scout'

export const getSenderScoutType = (
  isSystemRecommendJob: boolean,
  isCompanyScout: boolean,
): ScoutSenderType => {
  if (isSystemRecommendJob) {
    return 'systemRecommendedJob'
  }
  if (isCompanyScout) {
    return 'companyScout'
  } else {
    return 'careerAdviserScout'
  }
}
export const getInquiryScoutType = (
  isSkipDocumentScreening: boolean,
  isNormalScout: boolean,
): ScoutInquiryType => {
  if (isSkipDocumentScreening) return 'skipDocumentScreeningScout'
  else if (isNormalScout) return 'normalScout'
  return 'systemRecommendedJob'
}

import { useMemo } from 'react'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import CheckIcon from '@/assets/icon/jobDetail/CheckIcon'
import { GoodIcon } from '@/assets/icon/scout/GoodIcon'
import CurrentYen from '@/assets/icon/Tag/CurrentYen.svg'
import LocationMarker from '@/assets/icon/Tag/LocationMarker.svg'
import { getScoutSenderIcon, ScoutInquiryTypeTag } from '@/components/scout/parts/ScoutCardParts'
import { prefectures } from '@/const/prefectures'
import { useInquiry } from '@/hooks/useInquiry'
import Tag from '@/modules/components/Tag'
import { CharacterTitle } from '@/modules/const/Character'
import { formatDate } from '@/modules/utils/datetimeFormatter'
import { TalentScout } from '@/oas/talent/api'
import { getInquiryScoutType, getSenderScoutType } from '@/usecases/scout/getScoutType'
import { salaryConvertor } from '@/utils/salaryConvertor'

type Props = {
  scout: TalentScout
  isTop?: boolean
  isNoInterested?: boolean
}

/**
 * カード下部の一連のタグ表示
 */
const ScoutInfoTags = ({ scout }: { scout: TalentScout }) => {
  const jobForIncomeText = useMemo(() => {
    const { job } = scout
    const minMonthlyIncome = job?.min_monthly_income
      ? salaryConvertor(job?.min_monthly_income)
      : null
    const maxMonthlyIncome = job?.max_monthly_income
      ? salaryConvertor(job?.max_monthly_income)
      : null
    return minMonthlyIncome && maxMonthlyIncome
      ? minMonthlyIncome === maxMonthlyIncome
        ? `月収${minMonthlyIncome}`
        : `月収${minMonthlyIncome}万〜${maxMonthlyIncome}`
      : `年収${job?.min_income}万〜${job?.max_income}`
  }, [scout, scout.job])
  return (
    <Flex flexDirection="column" gap={2}>
      <Flex gap={1} flexWrap="wrap">
        <Tag>
          <Image src={CurrentYen.src} width={12} height={12} alt="年収" />
          {jobForIncomeText}万円
        </Tag>
        <Tag>
          <Image src={LocationMarker.src} width={12} height={12} alt="勤務地" />
          {
            prefectures.find(
              (prefecture) => prefecture.id === scout.best_work_location_prefecture_master_id,
            )?.label
          }
        </Tag>
        {scout.job.is_more_than_120_days_off_per_year && <Tag>年間休日120日以上</Tag>}
        {scout.job.is_overtime_under_20 && <Tag>残業月20時間以内</Tag>}
      </Flex>
    </Flex>
  )
}

export const ScoutCard = ({ scout, isTop, isNoInterested = false }: Props) => {
  const router = useRouter()
  // 問い合わせに必要な値をglobalStateに保存する
  const { setInquiryOfScoutLocal, clearInquiryOfJobToLocal, clearInquiryOfScoutToLocal } =
    useInquiry()

  const scoutSenderType = getSenderScoutType(scout.is_system_recommend_job, scout.is_company_scout)
  // getInquiryScoutTypeの第２引数はisNormalScoutedであるが、scoutにはis_normal_scoutedがないため、is_system_recommend_jobを反転させている
  const scoutInquiryType = getInquiryScoutType(
    scout.skip_document_screening,
    !scout.is_system_recommend_job,
  )

  const handleOnClick = () => {
    clearInquiryOfJobToLocal()
    clearInquiryOfScoutToLocal()
    setInquiryOfScoutLocal({ scout: scout })
    router.push(`/scout/${scout.id}/inquiry`)
  }

  return (
    <Flex
      h={isTop ? '243.5px' : 'auto'}
      p="16px"
      bg="white"
      gap="16px"
      flexDirection="column"
      border={isTop ? '1px solid #E2E8F0' : 'none'}
      borderRadius={isTop ? '8px' : 0}
    >
      <Flex w="100%" gap="8px" flexDirection="column">
        <Flex gap="4px" alignItems="center">
          <ScoutInquiryTypeTag scoutType={scoutInquiryType} />
          {!isNoInterested && scout.feedbacked_at && (
            <Flex
              alignItems="center"
              p="6px 8px"
              borderRadius="4px"
              bg="brand.green"
              color="white"
              gap="4px"
              fontSize="12px"
              lineHeight="100%"
              fontWeight="bold"
            >
              <GoodIcon size="12px" />
              <Text>{formatDate(scout.feedbacked_at, 'M/d HH:mm')}</Text>
              <Text>にいいね</Text>
            </Flex>
          )}
        </Flex>
        <Flex w="100%" gap="8px" alignItems="flex-start">
          <Image
            src={getScoutSenderIcon(scoutSenderType, scout.id, scout.sender_image_url)}
            width={48}
            height={48}
            alt="スカウト送信者"
            priority
          />
          <Box w="full">
            <Text
              fontSize="16px"
              lineHeight="150%"
              fontWeight="bold"
              mb="4px"
              wordBreak="break-word"
            >
              {scout.company_name}
            </Text>
            <Text fontSize="13px" lineHeight="100%" color="text.secondary">
              {scout.is_company_scout
                ? `採用担当　${scout.sender_name}`
                : scoutSenderType === 'systemRecommendedJob'
                ? CharacterTitle
                : '求人紹介担当'}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex gap="8px" flexDirection="column">
        <Box>
          <Text
            fontSize="13px"
            lineHeight="175%"
            sx={
              isTop
                ? {
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                  }
                : {}
            }
          >
            {scout.job.job_title}
          </Text>
        </Box>
        <ScoutInfoTags scout={scout} />
      </Flex>
      {!isTop && (
        <Flex w="100%" gap="8px" alignItems="center">
          <Button
            size="md"
            fontWeight={700}
            w="100%"
            h="48px"
            p="10px 32px"
            borderRadius="100px"
            bg="text.link"
            color="white"
            _hover={{ bg: 'text.link' }}
            alignItems="center"
            gap="8px"
            isDisabled={scout.is_inquired}
            onClick={handleOnClick}
          >
            {scout.is_inquired ? <CheckIcon color="white" width={24} height={24} /> : '話を聞く'}
          </Button>
          <Button
            size="md"
            fontWeight={700}
            w="100%"
            h="48px"
            p="10px 32px"
            variant="outline"
            borderRadius="100px"
            bg="white"
            _hover={{ bg: 'white' }}
            alignItems="center"
            gap="8px"
            onClick={() => router.push(`/scout/${scout.id}`)}
          >
            詳しく見る
          </Button>
        </Flex>
      )}
    </Flex>
  )
}

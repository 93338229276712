import { Badge, BadgeProps } from '@chakra-ui/react'

interface Props extends BadgeProps {
  text: string
  isClosed?: boolean
}

const MainBadge: React.FC<Props> = (props: Props) => {
  const { text, isClosed } = props

  return (
    <Badge
      p="6px 8px"
      w="fit-content"
      borderRadius="4px"
      fontSize="12px"
      lineHeight="12px"
      fontWeight={700}
      bg={isClosed ? 'surface.secondary' : 'brand.green'}
      color={isClosed ? 'text.secondary' : 'text.invert'}
      {...props}
    >
      {text}
    </Badge>
  )
}

export default MainBadge
